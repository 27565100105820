<template>
  <ion-page>
    <div v-if="showModal" @click="showModal = false" class="opm-modal">
      <ion-card style="width: 80%;border-radius: 15px" class="bg" @click.stop="showModal = true">
          <ion-card-header style="margin: 15px">
            <img src="https://static.yuzhangmen.cn/logo.jpg?x-oss-process=image/resize,w_140" style="width:70px;box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);border-radius: 10px" />
            <h2 style="color: #000">下载鱼掌门App</h2>
            <h5 style="margin-top: -2px">指尖上的澳洲房产管家</h5>
          </ion-card-header>
          <ion-card-content style="text-align: center;">
            <ion-button download :href="getDownloadUrl()" style="color: #fff;width: 98%">立即下载</ion-button>
          </ion-card-content>
      </ion-card>
    </div>
    <ion-item lines="none" style="--background: #2B2F3F;">
      <ion-thumbnail style="--border-radius: 10px">
        <img src="https://static.yuzhangmen.cn/logo.jpg?x-oss-process=image/resize,w_100" />
      </ion-thumbnail>
      <ion-label class="ion-text-wrap" style="margin-left: 10px;color:#fff">
        鱼掌门App
        <p style="color: #fff">指尖上的澳洲房产管家</p>
      </ion-label>
      <ion-button slot="end" download :href="getDownloadUrl()" style="color: #fff;margin-right: 20px" size="medium">下载App</ion-button>
    </ion-item>
    <ion-content :fullscreen="true" v-if="talk.content">
      <ion-item lines="none" style="--border-width: 0">
        <ion-avatar slot="start">
          <img v-if="talk.user.avatar" :src="talk.imageHost + talk.user.avatar" />
          <ion-icon v-else :icon="personCircle" color="primary" />
        </ion-avatar>
        <ion-label class="ion-text-wrap">
          <h2>
            {{ talk.user.fullName }}
          </h2>
          <p style="color: #8F93A0">{{dayjs(talk.createdTime).utc().format('MM-DD HH:mm')}}</p>
        </ion-label>
      </ion-item>

      <div class="ion-padding">
        <b style="color: #34394D;font-size: 18px">{{ talk.title }}</b>
        <p style="color: #34394D" v-html="talk.content.replace(/\n/g, '<br/>')">
        </p>
        <div>
          <ion-grid style="--ion-grid-padding: 0px">
            <ion-row>
              <ion-col :size="colSize" v-for="(media, ind) in talk.medias" :key="ind">
                <video v-if="!media.format" :poster="`${media.url}?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,m_fast,ar_auto`" controls class="video-source"
                       style="object-fit:fill;width:100%"
                       webkit-playsinline="true"
                       x-webkit-airplay="true"
                       playsinline="true"
                       x5-video-player-type="h5"
                       x5-video-orientation="h5"
                       x5-video-player-fullscreen="true"
                       preload="auto">
                  <source :src="media.url" type="video/mp4">
                </video>
                <template v-else>
                  <ion-img :src="media.url" :style="{ height: media.loaded ? '100%' : 0, width: media.loaded ? '100%' : 0, 'object-fit': 'cover' }" @ionImgWillLoad="ionImgWillLoad" @ionImgDidLoad="ionImgDidLoad(media)" />
                  <ion-img v-if="!media.loaded" :src="`${media.url}?x-oss-process=image/resize,w_100`" style="height: 100%;width: 100%;object-fit: cover" />
                </template>
              </ion-col>
            </ion-row>
          </ion-grid>
          <!--          <span class="date">-->
          <!--            {{ dayjs(talk.createdTime).utc().format('YYYY-MM-DD HH:mm') }}-->
          <!--          </span>-->
        </div>
        <div>
          <ion-button fill="clear" style="--padding-start: 0.4em;--padding-end:0.4em" @click="showModal = true">
            <ion-icon slot="start" :icon="chatboxEllipsesOutline"></ion-icon>{{ talk.commentCount }}
          </ion-button>
          <ion-button fill="clear" @click="showModal = true">
            <ion-icon slot="start" :icon="heartOutline"></ion-icon>
            {{ talk.likeCount }}
          </ion-button>
          <ion-button fill="clear" @click="showModal = true">
            <ion-icon slot="start" :icon="starOutline"></ion-icon>
            {{ talk.favoriteCount }}
          </ion-button>
        </div>
      </div>
      <ion-list v-if="comments.length > 0">
        <ion-item>
          <ion-label>全部评论</ion-label>
        </ion-item>
        <ion-item v-for="comment in comments" :key="comment.id">
          <ion-avatar slot="start" style="align-self: flex-start;margin-top: 10px">
            <img :src="comment.imageHost + comment.user.avatar" />
          </ion-avatar>
          <ion-label class="ion-text-wrap">
            <h2>
              {{ comment.user.fullName }}
            </h2>
            <p>{{dayjs(comment.createdTime).fromNow()}}</p>
            <div>
              <p>{{ comment.content }}</p>
            </div>
          </ion-label>
        </ion-item>
      </ion-list>
      <div class="fixed-button">
        <ion-button download :href="getDownloadUrl()" style="color: #fff;">在App内打开</ion-button>
      </div>
      <div class="fixed-button">
        <wx-open-launch-app id="launch-btn" appid="wxc13aabe6200ce437" :extinfo="extinfo">
            <div v-is="'script'" type="text/wxtag-template">
              <div v-is="'style'">.btn{padding:0.8em;opacity:0}</div>
              <button class="btn">在App内打开</button>
            </div>
        </wx-open-launch-app>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute } from 'vue-router';
import { IonContent, IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonPage, IonAvatar, IonImg, IonIcon, IonButton, IonThumbnail, IonCard, IonCardHeader, IonCardContent } from '@ionic/vue';
import { heartOutline, starOutline, chatboxEllipsesOutline, personCircle } from 'ionicons/icons';
import { defineComponent } from 'vue';
import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import customParseFormat from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import wx from "weixin-js-sdk"

dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(utc)

export default defineComponent({
  name: 'TalkDetail',
  data() {
    return {
      heartOutline,
      starOutline,
      chatboxEllipsesOutline,
      personCircle,
      talk: { title: '', medias: [{loaded: false}], content: '', user: {}},
      comments: [],
      colSize: 1,
      appId: 'wx300b2f002a93c35f',
      request: axios.create({baseURL: 'https://opmapi.yuzhangmen.cn/opm/'}),
      route: useRoute(),
      extinfo:'',
      showModal: false,
      dayjs,
      getDownloadUrl: () => {
        const mode = window && window.Ionic && window.Ionic.mode;
        return mode === 'ios' ? 'https://apps.apple.com/cn/app/%E9%B1%BC%E6%8E%8C%E9%97%A8/id1477809525' : 'https://a.app.qq.com/o/simple.jsp?pkgname=com.adinnet.ironfish&channel=0002160650432d595942&fromcase=60001';
      }
    }
  },
  mounted() {
    this.request.get(`/talk/detail?talkId=${this.route.params.id}`).then(resp => {
      this.talk = resp.data.result
      this.extinfo = `yzm:/${location.pathname}?mediaType=${this.talk.mediaType}`
      document.title = this.talk.title
      const len = this.talk.medias.length
      this.colSize = len === 1 ? 12 : len < 5 ? 6 : 4
      this.initWx()
    })
    this.request.get(`/talk/comments?talkId=${this.route.params.id}`).then(resp => {
      this.comments = resp.data.result
    })
  },
  methods: {
    initWx() {
      this.request.get(`/weixin/config?appId=${this.appId}&url=${encodeURIComponent(location.href)}`).then(resp=> {
        const result = resp.data.result
        if (!wx) {
          return
        }
        wx.config({
          // debug: true,
          appId: this.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'chooseImage', 'previewImage'],
          openTagList: ['wx-open-launch-app']
        });
        const media = this.talk.medias[0]
        const imgUrl = media.format ? media.url : `${media.url}?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,m_fast,ar_auto`
        const share = { title: document.title, desc: this.talk.content, link: location.href, imgUrl: imgUrl, success: () => {
          console.log('设置成功')
        }}
        wx.ready(() => {
          wx.updateAppMessageShareData(share)
          wx.updateTimelineShareData(share)
          wx.onMenuShareWeibo(share)
          wx.onMenuShareQZone(share)
          const btn = document.getElementById('launch-btn')
          btn.addEventListener('launch', () => {
            console.log('success')
          })
          btn.addEventListener('error', (e) => {
            alert(e.detail)
          })
        })
      })
    },
    ionImgWillLoad(val, event) {
      console.log(val, event)
    },
    ionImgDidLoad(media) {
      media.loaded = true
    }
  },
  components: {
    IonImg,
    IonRow,
    IonGrid,
    IonCol,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonPage,
    IonAvatar,
    IonIcon,
    IonButton,
    IonThumbnail, IonCard, IonCardHeader, IonCardContent
  },
});
</script>

<style scoped>
ion-item {
  --inner-padding-end: 0;
  --background: transparent;
}

ion-label {
  margin-top: 12px;
  margin-bottom: 12px;
}

ion-button{
  --padding-start: 0.4em;
  --padding-end: 0.4em;
  --color: grey;
}

.opm-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center
}

.opm-modal .bg {
  background-image: url("/assets/modal-bg.png");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: bottom right;
}

.fixed-button {
  position: fixed;
  bottom:60px;
  display: inline;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index:10000
}

.date {
  font-size: 12px;
  color: grey;
}

ion-item h2 {
  font-weight: 600;
}

ion-item ion-icon {
  font-size: 42px;
  margin-right: 8px;
}

ion-item ion-note {
  font-size: 15px;
  margin-right: 12px;
  font-weight: normal;
}

h1 {
  margin: 0;
  font-weight: bold;
  font-size: 22px;
}

p {
  line-height: 22px;
}
</style>